<template>
  <div>
    <b-card
      no-body
    >
      <b-card-header class="pb-50">
        <h5>
          {{ $t('spot.relatorio.titlePrimeiroCard') }}
        </h5>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            md="2"
            class="mb-md-0 mb-2"
          >
            <label>{{ $t('spot.relatorio.labelDataIncial') }}</label>
            <flat-pickr
              v-model="dataBuscaFilter.inicio"
              class="form-control"
              :config="{ altInput: true, enableTime: false, altFormat: 'd/m/Y', locale: Portuguese}"
            />
          </b-col>
          <b-col
            cols="12"
            md="2"
            class="mb-md-0 mb-2"
          >
            <label>{{ $t('spot.relatorio.labelDataFinal') }}</label>
            <flat-pickr
              v-model="dataBuscaFilter.fim"
              class="form-control"
              :config="{ altInput: true, enableTime: false, altFormat: 'd/m/Y', locale: Portuguese}"
            />
          </b-col>
          <b-col
            cols="12"
            md="2"
            class="mb-md-0 mb-2"
          >
            <label>{{ $t('spot.relatorio.labelCoin') }}</label>
            <b-form-input
              id="coin"
              v-model="dataBuscaFilter.coin"
            />
          </b-col>
          <b-col
            v-show="false"
            cols="12"
            md="2"
            class="mb-md-0 mb-2"
          >
            <label>{{ $t('spot.relatorio.fechamento.labelBase') }}</label>
            <b-form-select
              v-model="paridade"
              :options="optionsParidade"
              input-id="base-moeda"
            />
          </b-col>
          <b-col
            cols="12"
            md="2"
            class="mb-md-0 mb-2"
          >
            <label>{{ $t('spot.relatorio.fechamento.labelTrader') }}</label>
            <v-select
              v-model="dataBuscaFilter.agente"
              :options="agenteList"
              :label="'nome'"
              :reduce="agente => agente.id"
              class="w-100"
            />
          </b-col>
          <b-col
            cols="12"
            md="2"
            class="mb-md-0 mb-2"
          >
            <label style="visibility:hidden">{{ $t('spot.relatorio.labelAcao') }}</label>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="form-control"
              @click.prevent="buscarFiltro"
            >
              {{ $t('spot.relatorio.pesquisarBtn') }}
            </b-button>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <!-- Statistics -->

    <b-overlay
      :show="loadingOverlay"
      rounded="sm"
    >
      <b-card
        no-body
        class="card-statistics"
      >
        <b-card-header>
          <b-card-title>
            <div class="mb-1">
              {{ $t('futures.relatorio.fechamento.lucroRealizadoLiquido') }}
            </div>
            <h3 class="mb-1 font-weight-bolder">
              $ {{ resumo.totalLucro.toFixed(2) }}
            </h3>
          </b-card-title>

          <b-overlay
            v-show="false"
            :show="loadingOverlayExport"
            rounded="sm"
          >
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              @click.prevent="exportar"
            >
              <feather-icon
                icon="DownloadIcon"
                class="mr-50"
              />
              <span class="align-middle">{{ $t('spot.relatorio.fechamento.exportarBtn') }}</span>
            </b-button>
          </b-overlay>
        </b-card-header>

        <b-card-body>
          <b-row>
            <b-col
              cols="6"
              md="2"
              class="mb-2 mb-xl-0"
            >
              <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                v-b-tooltip.hover.top="$t('spot.relatorio.fechamento.tooltipQtdOperacoes')"
                variant="flat-primary"
                class="btn-icon"
                block
              >
                <b-media no-body>
                  <b-media-aside class="mt-1">
                    <feather-icon
                      size="24"
                      icon="WifiIcon"
                    />
                  </b-media-aside>
                  <b-media-body
                    :class="`my-auto ${ windowWidth >= 576 ? 'd-flex align-items-start ml-1' : '' }`"
                  >
                    <div>
                      <h3 class="font-weight-bolder mb-0">
                        {{ totalCount }}
                      </h3>
                      <p class="mb-0 text-secondary">
                        {{ $t('spot.relatorio.fechamento.textOperacoes') }}
                      </p>
                    </div>
                  </b-media-body>
                </b-media>
              </b-button>
            </b-col>
            <b-col
              cols="6"
              md="2"
              class="mb-2 mb-xl-0"
            >
              <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                v-b-tooltip.hover.top="$t('futures.relatorio.fechamento.tooltipTotalPnl')"
                variant="flat-primary"
                class="btn-icon"
                block
              >
                <b-media no-body>
                  <b-media-aside class="mt-1">
                    <feather-icon
                      size="24"
                      icon="DollarSignIcon"
                      class="text-success"
                    />
                  </b-media-aside>
                  <b-media-body
                    :class="`my-auto ${ windowWidth >= 576 ? 'd-flex align-items-start  ml-1' : '' }`"
                  >
                    <div>
                      <h3 class="font-weight-bolder mb-0">
                        {{ resumo.totalPNL.toFixed(2) }}
                      </h3>
                      <p class="mb-0 text-secondary">
                        {{ $t('futures.relatorio.fechamento.totalPnl') }}
                      </p>
                    </div>
                  </b-media-body>
                </b-media>
              </b-button>
            </b-col>
            <b-col
              cols="6"
              md="2"
              class="mb-2 mb-xl-0"
            >
              <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                v-b-tooltip.hover.top="$t('futures.relatorio.fechamento.tooltipSomaPorcResult')"
                variant="flat-primary"
                class="btn-icon"
                block
              >
                <b-media no-body>
                  <b-media-aside class="mt-1">
                    <feather-icon
                      size="24"
                      icon="TrendingUpIcon"
                      class="text-success"
                    />
                  </b-media-aside>
                  <b-media-body
                    :class="`my-auto ${ windowWidth >= 576 ? 'd-flex align-items-start  ml-1' : '' }`"
                  >
                    <div>
                      <h3 class="font-weight-bolder mb-0">
                        {{ resumo.totalPorcent.toFixed(2) }}
                      </h3>
                      <p class="mb-0 text-secondary">
                        {{ $t('spot.relatorio.fechamento.textSomaPorcResult') }}
                      </p>
                    </div>
                  </b-media-body>
                </b-media>
              </b-button>
            </b-col>
            <b-col
              cols="6"
              md="2"
              class="mb-2 mb-xl-0"
            >
              <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                v-b-tooltip.hover.top="$t('futures.relatorio.fechamento.tooltipTaxa')"
                variant="flat-primary"
                class="btn-icon"
                block
              >
                <b-media no-body>
                  <b-media-aside class="mt-1">
                    <feather-icon
                      size="24"
                      icon="DivideIcon"
                      class="text-success"
                    />
                  </b-media-aside>
                  <b-media-body
                    :class="`my-auto ${ windowWidth >= 576 ? 'd-flex align-items-start  ml-1' : '' }`"
                  >
                    <div>
                      <h3 class="font-weight-bolder mb-0">
                        {{ resumo.totalTaxas.toFixed(2) }}
                      </h3>
                      <p class="mb-0 text-secondary">
                        {{ $t('futures.relatorio.fechamento.totalTaxa') }} <small class="text-secondary">(USDT)</small>
                      </p>
                    </div>
                  </b-media-body>
                </b-media>
              </b-button>
            </b-col>

          </b-row>
        </b-card-body>
      </b-card>
    </b-overlay>

    <!-- Table Container Card -->
    <b-card
      no-body
    >
      <b-table
        ref="refListTable"
        :busy="loadingOverlay"
        :items="dataList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('spot.emptyText')"
        :sort-desc.sync="isSortDirDesc"
        striped
      >

        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle" />
          </div>
        </template>

        <template #cell(paridade)="data">
          <div class="text-nowrap">
            <b-avatar
              size="sm"
              :src="getIcon(data.item.moedaTrade)"
            />
            <span class="mr-1">
              {{ data.item.moedaTrade }}<span class="font-weight-light">/{{ data.item.paridadeTrade }}</span>
            </span>
          </div>
        </template>

        <template #cell(pnl)="data">
          <b-badge :variant="parseFloat(data.value) >=0 ? 'light-success' : 'light-danger'">
            {{ toFixed(data.item.pnl,4) }} {{ data.item.paridadeTrade }}
            <br>
            <small>
              ({{ getPercentDiff(data.item.totalCompra, data.item.total) }}%)
            </small>
          </b-badge>
        </template>

        <template #cell(lucroLiquido)="data">
          <b-badge :variant="parseFloat(data.value) >=0 ? 'light-success' : 'light-danger'">
            {{ toFixed(data.value, 4) }} {{ data.item.paridadeTrade }}
          </b-badge>
        </template>

        <template #cell(taxaTotal)="data">
          <template v-if="data.value">
            {{ toFixed(data.value, 4) }} <small>{{ data.item.paridadeTrade }}</small>
          </template>
        </template>

        <template #cell(dataCriacaoCompra)="data">
          {{ formatDateTimeSemAnoBR(data.value) }}
        </template>

        <template #cell(dataExecucao)="data">
          {{ formatDateTimeSemAnoBR(data.value) }}
        </template>

        <template #cell(agente)="data">
          {{ data.value }} <br>
          <b-badge variant="light-primary">
            #{{ data.item.sinalId }}
          </b-badge>
        </template>

      </b-table>

      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ $t('spot.textMuted', { from: paginationMeta.from, to: paginationMeta.to, of: paginationMeta.of }) }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalCount"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>

    </b-card>
  </div>
</template>

<script>

import {
  BCard, BTable, BAvatar, BOverlay, BSpinner, BPagination, BRow, BCol, BBadge, BCardHeader, BCardBody, BButton, BMedia, BMediaAside, BMediaBody, VBTooltip, BCardTitle, BFormSelect, BFormInput,
} from 'bootstrap-vue'
import store from '@/store'
import utilsMixin from '@/mixins/utilsMixin'
import { formatDateTimeSemAnoBR, percentDiferenca, toIsoStringTimeZone } from '@/utils/filter'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import { Portuguese } from 'flatpickr/dist/l10n/pt'
import Ripple from 'vue-ripple-directive'
import fechamentoStoreModule from './fechamentoStoreModule'

const RELATORIO_FECHAMENTO_SPOT_STORE_MODULE_NAME = 'fechamento-relatorio-spot'

export default {

  components: {
    BCard,
    BTable,
    BAvatar,
    BPagination,
    BRow,
    BCol,
    BBadge,
    BCardHeader,
    BCardBody,
    flatPickr,
    BButton,
    BMedia,
    BMediaAside,
    BMediaBody,
    BCardTitle,
    BOverlay,
    BFormSelect,
    BSpinner,
    BFormInput,
    vSelect,
  },

  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },

  mixins: [utilsMixin],

  data() {
    return {
      loadingOverlayExport: false,
      loadingOverlay: false,
      Portuguese,
      formatDateTimeSemAnoBR,
      percentDiferenca,
      perPage: 10,
      totalCount: 0,
      currentPage: 1,
      sortBy: 'dataExecucao,desc',
      isSortDirDesc: true,
      dataList: [],
      optionsParidade: [
        { value: null, text: '' },
        { value: 'USDT', text: 'USDT' },
        { value: 'BTC', text: 'BTC' },
      ],
      paridade: null,
      dataBuscaFilter: {
        coin: null,
        agente: null,
        inicio: new Date(new Date().getFullYear(), new Date().getMonth(), '01'), // primeiro dia do mes atual
        fim: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
      },
      resumo: {
        totalLucro: 0,
        totalTaxas: 0,
        totalPNL: 0,
        totalPorcent: 0,
      },
    }
  },

  computed: {
    tableColumns() {
      return [
        { key: 'paridade', label: this.$i18n.t('spot.relatorio.fechamento.tableHeader.paridade') },
        { key: 'totalCompra', label: this.$i18n.t('spot.relatorio.fechamento.tableHeader.totalCompra') },
        { key: 'total', label: this.$i18n.t('spot.relatorio.fechamento.tableHeader.total') },
        { key: 'pnl', label: this.$i18n.t('spot.relatorio.fechamento.tableHeader.pnl') },
        { key: 'taxaTotal', label: this.$i18n.t('spot.relatorio.fechamento.tableHeader.taxaTotal') },
        { key: 'lucroLiquido', label: this.$i18n.t('spot.relatorio.fechamento.tableHeader.lucroLiquido') },
        { key: 'dataCriacaoCompra', label: this.$i18n.t('spot.relatorio.fechamento.tableHeader.dataCriacaoCompra') },
        { key: 'dataExecucao', label: this.$i18n.t('spot.relatorio.fechamento.tableHeader.dataExecucao') },
        { key: 'agente', label: this.$i18n.t('spot.relatorio.fechamento.tableHeader.trader') },
      ]
    },
    paginationMeta() {
      const localItemsCount = this.$refs.refListTable ? this.$refs.refListTable.localItems.length : 0
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.totalCount,
      }
    },

    windowWidth() {
      return this.$store.state.app.windowWidth
    },
  },

  watch: {
    currentPage() {
      this.fetchList()
      this.$refs.refListTable.refresh()
    },
  },

  created() {
    if (!store.hasModule(RELATORIO_FECHAMENTO_SPOT_STORE_MODULE_NAME)) store.registerModule(RELATORIO_FECHAMENTO_SPOT_STORE_MODULE_NAME, fechamentoStoreModule)
    this.getAgentes()
    this.fetchList()
  },

  beforeDestroy() {
    if (store.hasModule(RELATORIO_FECHAMENTO_SPOT_STORE_MODULE_NAME)) store.unregisterModule(RELATORIO_FECHAMENTO_SPOT_STORE_MODULE_NAME)
  },

  methods: {

    getAgentes() {
      store
        .dispatch('fechamento-relatorio-spot/getAgentes')
        .then(response => {
          this.agenteList = response.data
        })
    },

    buscarFiltro() {
      if (this.isDataRangeDias(this.dataBuscaFilter.inicio, this.dataBuscaFilter.fim, 90)) {
        this.page = 1
        this.fetchList()
      }
    },

    exportar() {
      this.loadingOverlayExport = true

      let dataInicio = this.dataBuscaFilter.inicio
      if (typeof (dataInicio) === 'string' || dataInicio instanceof String) {
        dataInicio = toIsoStringTimeZone(new Date(dataInicio.split('-')[0], dataInicio.split('-')[1] - 1, dataInicio.split('-')[2]))
      }

      let dataFim = this.dataBuscaFilter.fim

      if (typeof (dataFim) === 'string' || dataFim instanceof String) {
        const dataFimTemp = new Date(dataFim.split('-')[0], dataFim.split('-')[1] - 1, dataFim.split('-')[2])
        dataFim = new Date(dataFimTemp.setDate(dataFimTemp.getDate() + 1)).toISOString()
      } else {
        dataFim = new Date(dataFim.setDate(dataFim.getDate() + 1))
      }

      const paridadeTemp = this.paridade

      store
        .dispatch('fechamento-relatorio-spot/getVendasExport', {
          dataInicio,
          dataFim,
          paridade: paridadeTemp,
          status: '3',
        }).then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `fechamento_${new Date().getTime()}.xlsx`)
          document.body.appendChild(link)
          link.click()
        }).finally(() => {
          this.loadingOverlayExport = false
        })
    },

    fetchList() {
      this.loadingOverlay = true

      let dataInicio = this.dataBuscaFilter.inicio
      if (typeof (dataInicio) === 'string' || dataInicio instanceof String) {
        dataInicio = toIsoStringTimeZone(new Date(dataInicio.split('-')[0], dataInicio.split('-')[1] - 1, dataInicio.split('-')[2]))
      }

      let dataFim = this.dataBuscaFilter.fim

      if (typeof (dataFim) === 'string' || dataFim instanceof String) {
        const dataFimTemp = new Date(dataFim.split('-')[0], dataFim.split('-')[1] - 1, dataFim.split('-')[2])
        dataFim = new Date(dataFimTemp.setDate(dataFimTemp.getDate() + 1)).toISOString()
      } else {
        dataFim = new Date(dataFim.setDate(dataFim.getDate() + 1))
      }

      const paridadeTemp = this.paridade

      store
        .dispatch('fechamento-relatorio-spot/getVendasResumo', {
          size: this.perPage,
          page: this.currentPage - 1,
          sortBy: this.sortBy,
          sortDesc: this.isSortDirDesc,
          dataInicio,
          dataFim,
          paridade: paridadeTemp,
          status: '3',
          coin: this.dataBuscaFilter.coin,
          agente: this.dataBuscaFilter.agente,
        })
        .then(response => {
          this.resumo = response.data
        })

      store
        .dispatch('fechamento-relatorio-spot/getVendas', {
          size: this.perPage,
          page: this.currentPage - 1,
          sortBy: this.sortBy,
          sortDesc: this.isSortDirDesc,
          dataInicio,
          dataFim,
          paridade: paridadeTemp,
          status: '3',
          coin: this.dataBuscaFilter.coin,
          agente: this.dataBuscaFilter.agente,
        })
        .then(response => {
          const { content, totalElements } = response.data
          this.dataList = content
          this.totalCount = totalElements
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro ao recuperar a lista.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }).finally(() => {
          this.loadingOverlay = false
        })
    },
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
